import { UseScrollToElementInterface } from './useScrollToElement';

export const useScrollToElement = (): UseScrollToElementInterface => {
  const scrollTo = (id: string) => {
    const elementPosition = window.document.getElementById(id)?.offsetTop + 120;
    window.scrollTo({
      top: elementPosition,
      behavior: 'smooth'
    });
  }

  return {
    scrollTo,
  }
}
