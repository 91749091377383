













































import {
  ref,
  defineComponent,
  useRouter,
  useContext,
  useMeta,
  onMounted,
} from '@nuxtjs/composition-api';
import LoginForm from '~/modules/customer/components/LoginModal/forms/LoginForm.vue';
import RegisterForm from '~/modules/customer/components/LoginModal/forms/RegisterForm.vue';
import ForgotPasswordForm from '~/modules/customer/components/LoginModal/forms/ForgotPasswordForm.vue';
import ForgotPasswordThankYou from '~/modules/customer/components/LoginModal/forms/ForgotPasswordThankYou.vue';
import {
  ForgotPasswordFormFields, LoginFormFields, FormName,
} from '~/modules/customer/components/LoginModal/forms/types';
import { useAuth } from '@theme/composables/useAuth';
import { SignUpForm } from '@theme/composables/useAuth/useAuth';
import { useForgotPassword } from '~/modules/customer/composables/useForgotPassword';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'LoginPage',
  components: {
    LoginForm,
    RegisterForm,
    ForgotPasswordForm,
    ForgotPasswordThankYou,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // @ts-ignore-next-line
      vm.setReferrer(from.fullPath);
    })
  },
  setup() {
    const {
      login,
      loading,
      error: userError,
    } = useUser();

    const referrer = ref('')

    const { app: { localePath } } = useContext();
    const router = useRouter();
    const { signUp } = useAuth();
    const { shouldResetPassword } = storeToRefs(useCustomerStore());
    const { request: resetPassword, error: forgotPasswordError, loading: forgotPasswordLoading } = useForgotPassword();

    const currentlyDisplayedForm = ref<FormName>('login');

    const loginForm = ref<LoginFormFields>({
      email: '',
      password: '',
    });
    const registerForm = ref<SignUpForm>({
      email: '',
      firstName: '',
      titleCode: '',
      lastName: '',
      pwd: '',
      checkPwd: '',
      informationByPost: false,
      informationByEmail: false,
    });
    const forgotPasswordForm = ref<ForgotPasswordFormFields>({
      username: '',
    });

    const redirectToReferrer = () => {
      if (router.currentRoute.query['referrerName']) {
        router.push(localePath({ name: String(router.currentRoute.query.referrerName) }));
      } else if (router.currentRoute.query['referrerPath']) {
        router.push(decodeURIComponent(String(router.currentRoute.query.referrerPath)));
      } else if (router.currentRoute.query['redirectTo']) {
        switch (router.currentRoute.query['redirectTo']) {
        case 'checkout':
          window.location.assign('/checkout/multi/delivery');
          break;

        case 'cellarPlanLogin':
          window.location.assign('/cellar-plan/signup-payments');
          break;

        default:
          router.push('/');
          break;
        }
      } else if (referrer.value && !referrer.value.includes('login')) {
        router.push(referrer.value);
      } else {
        router.push('customer/my-account')
      }
    };

    const onLoginFormSubmit = async (form: LoginFormFields) => {
      loginForm.value = form;

      await login({ user: { ...form } });

      // Redirect to the referrer page if the user is logged in successfully without restrictions
      if (!userError.value.login && !shouldResetPassword.value) {
        redirectToReferrer();
      }
    };

    const onRegisterFormSubmit = async (form: SignUpForm) => {
      await signUp(form);

      if (!userError.value.register) {
        redirectToReferrer();
      }
    };

    const onForgotPasswordFormSubmit = async (form: ForgotPasswordFormFields) => {
      forgotPasswordForm.value = form;
      await resetPassword({ email: form.username });
      currentlyDisplayedForm.value = 'forgotPasswordThankYou';
    };

    const changeForm = (value) => {
      currentlyDisplayedForm.value = value;

      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const setReferrer = (fullPath) => referrer.value = fullPath;

    useMeta({
      title: 'Sign in | Berry Bros. & Rudd'
    });

    onMounted(() => window?.scroll(0, 0));

    return {
      userError,
      loading,
      forgotPasswordError,
      forgotPasswordLoading,
      currentlyDisplayedForm,
      loginForm,
      registerForm,
      forgotPasswordForm,
      referrer,
      onRegisterFormSubmit,
      onLoginFormSubmit,
      onForgotPasswordFormSubmit,
      changeForm,
      setReferrer,
    };
  },
  head: {}
});
