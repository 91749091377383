var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{class:[_vm.data.class, _vm.data.staticClass, 'sf-quantity-selector'],style:([_vm.data.style, _vm.data.staticStyle]),attrs:{"aria-label":_vm.props.ariaLabel}},[_c(_vm.injections.components.SfButton,{tag:"component",staticClass:"sf-button--pure sf-quantity-selector__button",attrs:{"disabled":_vm.props.disabled || Boolean(_vm.props.min !== null && _vm.props.qty <= _vm.props.min),"data-testid":"decrease"},on:{"click":function($event){_vm.$options.handleInput(
        Number(_vm.props.qty) - _vm.props.step,
        _vm.listeners,
        _vm.props.min,
        _vm.props.max
      )}}},[_vm._v("\n    −\n  ")]),_vm._v(" "),_c(_vm.injections.components.SfInput,{tag:"component",staticClass:"sf-quantity-selector__input",attrs:{"type":"number","input-mode":"numeric","name":_vm.$options.uniqueKey(),"value":Number(_vm.props.qty),"min":_vm.props.min,"step":_vm.props.step,"max":_vm.props.max,"disabled":_vm.props.disabled,"data-testid":"sf-quantity-selector input"},on:{"keydown":function($event){return _vm.$options.handleKeydown($event, _vm.listeners)},"input":function($event){return _vm.$options.handleInput($event, _vm.listeners, _vm.props.min, _vm.props.max)},"blur":function($event){return _vm.$options.handleBlur($event, _vm.listeners, _vm.props.min, _vm.props.max)},"wheel":function($event){return _vm.$options.handleWheel($event)}}}),_vm._v(" "),_c(_vm.injections.components.SfButton,{tag:"component",staticClass:"sf-button--pure sf-quantity-selector__button",attrs:{"disabled":_vm.props.disabled || Boolean(_vm.props.max !== null && _vm.props.qty >= _vm.props.max),"data-testid":"increase"},on:{"click":function($event){_vm.$options.handleInput(
        Number(_vm.props.qty) + _vm.props.step,
        _vm.listeners,
        _vm.props.min,
        _vm.props.max
      )}}},[_vm._v("\n    +\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }