


















































import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { CategoryTree } from '~/modules/GraphQL/types';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import { useSearchForCategories } from '@theme/composables/useSearchForCategories';
import type { DynamicLabel } from '@amplience/types';

// @TODO This logic is a temporary solution which will be update later to the CategoryFilterInput
type CategoriesSearchFilterInput = {
  name?: string;
  root_category_url_keys?: string[],
}

export default defineComponent({
  name: 'SearchCategories',
  directives: {
    clickOutside
  },
  props: {
    isDynamicSearch: {
      type: Boolean,
      default: false,
    },
    dynamicLabels: {
      type: Array as PropType<DynamicLabel[]>,
      default: () => ([]),
    },
    filters: {
      type: Object as PropType<CategoriesSearchFilterInput>,
      default: () => ({
        root_category_url_keys: [
          'wine-regional-hierarchy',
          'spirits-other-regional-hierarchy',
        ],
      }),
    },
    data: {
      type: Array as PropType<CategoryTree[]>,
      default: () => [],
    },
    searchLabel: {
      type: String,
      default: 'Search',
    },
  },
  setup(props, { emit }) {
    const { localePath } = useContext();
    const input = ref<string>('');
    const results = ref<CategoryTree[]>([]);
    const isResultOpened = ref<boolean>(false);
    const noResult = ref<boolean>(false);
    const {
      categoriesSearchList,
      search,
      searchResult,
    } = useSearchForCategories(props.isDynamicSearch);

    const setClosed = (): boolean => isResultOpened.value = false;

    const submitSearch = (): void => {
      setClosed();

      if (input.value.length > 1) {
        emit('submit-search', false, categoriesSearchList.value);
      } else {
        emit('submit-search', true, []);
      }
    };

    const searchCategories = async (categories: CategoryTree[]): Promise<void> => {
      await search(input.value, categories, props.filters);

      results.value = searchResult.value;
      noResult.value = input.value && !results.value.length;
      isResultOpened.value = input.value.length > 1;
    };

    const clearSearch = () => {
      isResultOpened.value = false;
      input.value = '';
      document.getElementById('search-input').focus();
      emit('clear-search');
    };

    const getDynamicLabel = (category: CategoryTree): string => (
      props.dynamicLabels.reduce((label: string, config: DynamicLabel) => {
        if (config.label && config.regex.test(category[config.attribute])) {
          return `${config.label} - ${category.name}`;
        }

        return label;
      }, category.name)
    );

    const normalizeUrl = (urlKey: string): string => {
      return decodeURIComponent(
        localePath(urlKey.startsWith('/') ? urlKey : `/${urlKey}`),
      );
    };

    return {
      input,
      isResultOpened,
      noResult,
      results,
      setClosed,
      submitSearch,
      searchCategories,
      clearSearch,
      getDynamicLabel,
      normalizeUrl,
    };
  }
})
