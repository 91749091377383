







































































import { computed, defineComponent, ref, useRouter, watch } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { required, email } from 'vee-validate/dist/rules';
import { ValidationObserver, extend } from 'vee-validate';
import {
  SfButton,
  SfLoader,
} from '@storefront-ui/vue';
import { LoginFormFields } from './types';
import TextField from '@theme/components/form/TextField.vue';
import Notification from '@theme/components/molecules/Notification.vue';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  components: {
    SfButton,
    SfLoader,
    ValidationObserver,
    TextField,
    Notification,
  },
  props: {
    form: {
      type: Object as PropType<LoginFormFields>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();

    const formCopy = ref<LoginFormFields>();

    const notice = computed(() => {
      switch (router.currentRoute.query['reason']) {
      case 'inactivity':
        return {
          type: 'warning',
          message: 'Due to inactivity, you have been signed out. Please sign in again.',
          icon: 'icon-action-warning',
        };

      case 'password-reset':
        return {
          type: 'info',
          message: 'Your password has been successfully reset. Please sign in with your new password.',
          icon: 'icon-action-info',
        };

      default:
        return null;
      }
    });

    const signInText = computed(
      () => router.currentRoute.query['redirectTo'] === 'checkout' ? 'Sign in and checkout' : 'Sign in'
    );

    watch(() => props.form, (newForm) => { formCopy.value = { ...newForm }; }, { immediate: true, deep: true });

    return {
      formCopy,
      notice,
      signInText,
    };
  },
});
